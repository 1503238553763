header {
    &.site-header
    {
        background: -moz-linear-gradient(top,  rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%); 
        background: -webkit-linear-gradient(top,  rgba(0,0,0,1) 0%,rgba(255,255,255,0) 100%); 
        background: linear-gradient(to bottom,  rgba(0,0,0,1) 0%,rgba(255,255,255,0) 100%); 
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00ffffff',GradientType=0 );
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        justify-content: space-between;
        padding: 0 30px;
        z-index: 999;
        @include transition(0.4s);
        @media only screen and (max-width:$container){
            padding: 0 15px;
        }
        @media only screen and (max-width:$medium){
            padding: 15px;
        }
        .nav-bar {
            width: auto;
            align-items: stretch;
            @media only screen and (max-width:$medium){
                align-items: center;
            }
        
        }
        .site-logo{
            max-width: 251px;
            @media only screen and (max-width:$container){
               max-width: 210px;
            } 
            @media only screen and (max-width:$exLarge){
                max-width: 200px;
            }
            @media only screen and (max-width:$large){
                max-width: 150px;
            }
            @media only screen and (max-width:$large){
                max-width: 130px;
            }
            @media only screen and (max-width:$exSmall){
                max-width: 120px;
            }
        }
        nav {
            margin-left: 46px; 
            position: relative;
            @media only screen and (min-width:1025px) {
                @include flexboxImp;
            }
            @media only screen and (max-width:$container){
                margin-left: 30px;
            }
            @media only screen and (max-width:$large){
                margin-left: 20px;
            }
            @media only screen and (max-width:$medium){
                display: none;
                position: fixed;
                left: 0;
                right: 0;
                top: 64px;
                width: 100%;
                background-color: $primary-color;
                @include space-none;
                height: auto;
                max-height: calc(100vh - 66px);
                overflow-y: auto;
            }
           
            &:after{
                left: 0;
                @include divider;
                @media only screen and (max-width:$medium){
                    display: none;
                }
                
            }
            ul{
                margin: 0;
                @media only screen and (max-width:$medium){
                    @include flexbox;
                    flex-direction: column;
                }
                li{ 
                    margin-left: 46px;
                    @media only screen and (max-width:$container){
                        margin-left: 30px;
                    }
                    @media only screen and (max-width:$large){
                        margin-left: 20px;
                    }
                    @media only screen and (max-width:$medium){
                        @include flexbox;
                        flex-direction: column;
                        @include space-none;
                    }
                    
                    a{ 
                        color: $white-color;
                        font-size: 18px;
                        line-height: 24px;
                        padding: 38px 0;
                        text-transform: capitalize;
                        display: inline-block;
                        position: relative;
                        @media only screen and (max-width:$exLarge){
                            font-size: 14px;
                        }
                        @media only screen and (max-width:$large){
                            padding: 25px 0;
                        }
                        @media only screen and (max-width:$large){
                            padding: 20px 0;
                        }
                        @media only screen and (max-width:$medium){
                            width: 100%;
                            border: none;
                            border-bottom: 1px solid $black-color;
                            padding: 10px 15px;
                        }
                        &::after{
                            content: "";
                            position: absolute;
                            left: auto;
                            right: 0;
                            height: 8px;
                            bottom: 0;
                            @include border-radius(10px);
                            background-color: $primary-sky-color;
                            @include transition(0.4s);
                            width: 0;
                            @media only screen and (max-width:$medium){
                                display: none;
                            }

                        }
                        
                    }
                    &:hover,&.active{
                        a{
                            color: $primary-sky-color;
                            @media only screen and (max-width:$medium){
                                padding-left: 30px;
                                border-left: 5px solid $primary-sky-color;
                            }
                            &:after{
                                width: 100%;
                                left: 0;
                                right: auto;
                             
                            }
                        }   
                    }
                    &:last-child{
                        a{
                            border: none;
                        }
                    }
                }
            }
        }

        .site-search {
            justify-content: space-between;
            position: relative;
            align-items: center;
            .site-main-search {
                width: 330px;
                max-width: 100%;
                position: relative;
                align-items: center;
                @media only screen and (max-width:$exLarge){
                    width: 250px;
                }
                @media only screen and (max-width:$large){
                    width: 210px;
                }
                @media only screen and (max-width:$medium-1170){
                    width: auto;
                }
                 
                &::after{
                    right: -21px; 
                    @include divider;
                    @media only screen and (max-width:$large){
                        right: -20px;
                    }
                    // @media only screen and (max-width:$large){
                    //     right: -20px;
                    // }
                    // @media only screen and (max-width:$exSmall){
                    //     right: -15px;
                    // }
                    
                }
                
                    .search-input {
                        padding: 7px 16px;
                        min-height: 40px;
                        font-size: 18px;outline: none;border: none;width: 100%;
                        font-size: 14px;
                        @include font-family($font-body,400);
                        padding-right: 40px;
                        @media only screen and (max-width:$medium-1170){
                            display: none;
                        }
                    }
                    button {
                        @media only screen and (max-width:$medium-1170){
                            @include space-none;
                            font-size: 14px;
                            &::before{
                                color: $white-color;
                            }
                        }
                        @media only screen and (min-width:1171px) {
                            position: absolute;
                            top: -1px;
                            right: -1px;
                            bottom: -1px;
                            width: 50px;
                            font-size: 18px;
                        }
                        
                        
                    }
                }
                .btn-small{
                    margin-left: 41px;
                    @media only screen and (max-width:$large){
                        margin-left: 30px;
                    }
                    @media only screen and (max-width:$large){
                        margin-left: 40px;
                    }
                    @media only screen and (max-width:$exSmall){
                        margin-left: 30px;
                    }
                    &:hover {
                        background-color: $white-color;
                        color: $primary-color;
                        border-color: $white-color;
                    }
                }
            
            }
    }
    &.stickey-header{
        background-color: $primary-color;
    }   
    &.primary-header{
        background-color: $primary-color;
    }
    .mobile-humberger{
        width: 25px;
        height: 25px;
        margin-right: 20px;
        position: relative;
        @include flexbox;
        align-items: center;
        justify-content: center;
        @include transition(0.4s);
        font-size: 12px;
        @media only screen and (min-width:1025px) {
            display: none;
        }
        @media only screen and (max-width:$exSmall){
            margin-right: 15px;
        }
        &::before{
            content: "\e913";
            font-family: 'icomoon' !important;
            speak: never;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            color: $white-color;
            @include transition(0.4s);
        }
    }
}

body{
    &.js-mobile-menu{
        header{
            background-color: $primary-color;
            .mobile-humberger{
                &:before{
                    content: "\e912";
                }
            }
        }
    }
    
}












