
@font-face {
    font-family: 'Oswald-SemiBold';
    src: url('../../../fonts/Oswald-SemiBold.eot');
    src: url('../../../fonts/Oswald-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../../../fonts/Oswald-SemiBold.woff2') format('woff2'),
        url('../../../fonts/Oswald-SemiBold.woff') format('woff'),
        url('../../../fonts/Oswald-SemiBold.ttf') format('truetype'),
        url('../../../fonts/Oswald-SemiBold.svg#Oswald-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
        font-family: 'Oswald-ExtraLight';
        src: url('../../../fonts/Oswald-ExtraLight.eot');
        src: url('../../../fonts/Oswald-ExtraLight.eot?#iefix') format('embedded-opentype'),
            url('../../../fonts/Oswald-ExtraLight.woff2') format('woff2'),
            url('../../../fonts/Oswald-ExtraLight.woff') format('woff'),
            url('../../../fonts/Oswald-ExtraLight.ttf') format('truetype'),
            url('../../../fonts/Oswald-ExtraLight.svg#Oswald-ExtraLight') format('svg');
        font-weight: 200;
        font-style: normal;
        font-display: swap;
}
    
@font-face {
        font-family: 'Oswald-Light';
        src: url('../../../fonts/Oswald-Light.eot');
        src: url('../../../fonts/Oswald-Light.eot?#iefix') format('embedded-opentype'),
            url('../../../fonts/Oswald-Light.woff2') format('woff2'),
            url('../../../fonts/Oswald-Light.woff') format('woff'),
            url('../../../fonts/Oswald-Light.ttf') format('truetype'),
            url('../../../fonts/Oswald-Light.svg#Oswald-Light') format('svg');
        font-weight: 300;
        font-style: normal;
        font-display: swap;
}
    
@font-face {
        font-family: 'Oswald-Medium';
        src: url('../../../fonts/Oswald-Medium.eot');
        src: url('../../../fonts/Oswald-Medium.eot?#iefix') format('embedded-opentype'),
            url('../../../fonts/Oswald-Medium.woff2') format('woff2'),
            url('../../../fonts/Oswald-Medium.woff') format('woff'),
            url('../../../fonts/Oswald-Medium.ttf') format('truetype'),
            url('../../../fonts/Oswald-Medium.svg#Oswald-Medium') format('svg');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
}
    
@font-face {
        font-family: 'Oswald-Bold';
        src: url('../../../fonts/Oswald-Bold.eot');
        src: url('../../../fonts/Oswald-Bold.eot?#iefix') format('embedded-opentype'),
            url('../../../fonts/Oswald-Bold.woff2') format('woff2'),
            url('../../../fonts/Oswald-Bold.woff') format('woff'),
            url('../../../fonts/Oswald-Bold.ttf') format('truetype'),
            url('../../../fonts/Oswald-Bold.svg#Oswald-Bold') format('svg');
        font-weight: bold;
        font-style: normal;
        font-display: swap;
}
    
@font-face {
    font-family: 'Oswald-Regular';
    src: url('../../../fonts/Oswald-Regular.eot');
    src: url('../../../fonts/Oswald-Regular.eot?#iefix') format('embedded-opentype'),
        url('../../../fonts/Oswald-Regular.woff2') format('woff2'),
        url('../../../fonts/Oswald-Regular.woff') format('woff'),
        url('../../../fonts/Oswald-Regular.ttf') format('truetype'),
        url('../../../fonts/Oswald-Regular.svg#Oswald-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "OpenSans-Bold";
    src: url('../../../fonts/OpenSans-Bold.eot');
    src: url('../../../fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../../fonts/OpenSans-Bold.woff2') format('woff2'),
        url('../../../fonts/OpenSans-Bold.woff') format('woff'),
        url('../../../fonts/OpenSans-Bold.ttf') format('truetype'),
        url('../../../fonts/OpenSans-Bold.svg#OpenSans-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'OpenSans-ExtraBold';
    src: url('../../../fonts/OpenSans-ExtraBold.eot');
    src: url('../../../fonts/OpenSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../../../fonts/OpenSans-ExtraBold.woff2') format('woff2'),
        url('../../../fonts/OpenSans-ExtraBold.woff') format('woff'),
        url('../../../fonts/OpenSans-ExtraBold.ttf') format('truetype'),
        url('../../../fonts/OpenSans-ExtraBold.svg#OpenSans-ExtraBold') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'OpenSans-Light';
    src: url('../../../fonts/OpenSans-Light.eot');
    src: url('../../../fonts/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
        url('../../../fonts/OpenSans-Light.woff2') format('woff2'),
        url('../../../fonts/OpenSans-Light.woff') format('woff'),
        url('../../../fonts/OpenSans-Light.ttf') format('truetype'),
        url('../../../fonts/OpenSans-Light.svg#OpenSans-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'OpenSans-Medium';
    src: url('../../../fonts/OpenSans-Medium.eot');
    src: url('../../../fonts/OpenSans-Medium.eot?#iefix') format('embedded-opentype'),
        url('../../../fonts/OpenSans-Medium.woff2') format('woff2'),
        url('../../../fonts/OpenSans-Medium.woff') format('woff'),
        url('../../../fonts/OpenSans-Medium.ttf') format('truetype'),
        url('../../../fonts/OpenSans-Medium.svg#OpenSans-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'OpenSans-SemiBold';
    src: url('../../../fonts/OpenSans-SemiBold.eot');
    src: url('../../../fonts/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../../../fonts/OpenSans-SemiBold.woff2') format('woff2'),
        url('../../../fonts/OpenSans-SemiBold.woff') format('woff'),
        url('../../../fonts/OpenSans-SemiBold.ttf') format('truetype'),
        url('../../../fonts/OpenSans-SemiBold.svg#OpenSans-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'OpenSans-Regular';
    src: url('../../../fonts/OpenSans-Regular.eot');
    src: url('../../../fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('../../../fonts/OpenSans-Regular.woff2') format('woff2'),
        url('../../../fonts/OpenSans-Regular.woff') format('woff'),
        url('../../../fonts/OpenSans-Regular.ttf') format('truetype'),
        url('../../../fonts/OpenSans-Regular.svg#OpenSans-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
  font-family: 'icomoon';
  src:  url('../../../fonts/icomoon.eot');
  src:  url('../../../fonts/icomoon.eot#iefix') format('embedded-opentype'),
    url('../../../fonts/icomoon.ttf') format('truetype'),
    url('../../../fonts/icomoon.woff') format('woff'),
    url('../../../fonts/icomoon.svg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-minus:before {
  content: "\e91e";
}
.icon-plus:before {
  content: "\e91d";
}
.icon-invisible-eye-icon:before {
  content: "\e91a";
}
.icon-eye-icon:before {
  content: "\e91c";
}
.icon-arrow-left-icon:before {
  content: "\e91b";
}
.icon-surface:before {
  content: "\e918";
  color: #707070;
}
.icon-facebook:before {
  content: "\e919";
}
.icon-map-marker:before {
  content: "\e914";
  color: #707070;
}
.icon-career:before {
  content: "\e915";
  color: #707070;
}
.icon-envolpee:before {
  content: "\e916";
  color: #707070;
}
.icon-fax:before {
  content: "\e917";
  color: #707070;
}
.icon-Close:before {
  content: "\e912";
  color: #707070;
}
.icon-hamburger-icon:before {
  content: "\e913";
  color: #707070;
}
.icon-Download:before {
  content: "\e90c";
  color: #151f66;
}
.icon-link:before {
  content: "\e90d";
  color: #707070;
}
.icon-printer:before {
  content: "\e90e";
  color: #707070;
}
.icon-Laser-Cutting:before {
  content: "\e90f";
  color: #707070;
}
.icon-pdf:before {
  content: "\e910";
  color: #707070;
}
.icon-quote1:before {
  content: "\e911";
  color: #707070;
}
.icon-email:before {
  content: "\e90a";
  color: #707070;
}
.icon-user:before {
  content: "\e90b";
  color: #707070;
}
.icon-quote:before {
  content: "\e909";
}
.icon-call-icon:before {
  content: "\e906";
  color: #707070;
}
.icon-send:before {
  content: "\e907";
  color: #8faee6;
}
.icon-youtube:before {
  content: "\e908";
  color: #d0d2e0;
}
.icon-facebook-brands:before {
  content: "\e903";
  color: #707070;
}
.icon-linkedin-in-brands:before {
  content: "\e904";
  color: #707070;
}
.icon-twitter-brands:before {
  content: "\e905";
  color: #707070;
}
.icon-search:before {
  content: "\e902";
  color: #a2a2a2;
}
.icon-atech-checked-mark:before {
  content: "\e901";
  color: #fff;
}
.icon-attach-line-arrow:before {
  content: "\e900";
  color: #707070;
}
