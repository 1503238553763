@import '../app';
@import "../components/cmnHeroBanner";

.login-section{
    padding: 89px 0 95px;
    @media only screen and (max-width:$large){
        padding: 50px 0;
    }
    @media only screen and (max-width:$Small){
        padding: 30px 0;
    }
      form{
        width: 618px;
        margin: auto;

        @media only screen and (max-width:$Small){
            width: 100%;
            padding: 0 15px;
        }

        h3{
            @include font-family($oswald-bold, bold);
            width: 100%;
            margin-bottom: 0;
            &::after{
                content: '';
                display: block;
                width: 30px;
                background-color: #C0BDCC;
                height: 1px;
                margin: 26px 0 21px;
                @media only screen and (max-width:$Small){
                    margin: 20px 0 15px;
                }
            }
        }
        p{
            width: 100%;
            font-size: 14px;
        }

        .row{
            margin: 0 -10px;
          
           
            .form-group{
                // margin-bottom: 24px;
                padding: 0 10px;
                width: 50%;
                @media only screen and (max-width:$exSmall){
                    width: 100%;
                }
                // label{
                //     font-size: 16px;
                //       margin-bottom: 11px;
                // }
                // .form-control{
                //     font-size: 14px;
                //     color: $black-color;
                // }
              
                .input-wrapper{
                    position: relative;
                    .view-pass{
                        cursor: pointer;
                        position: absolute;
                        top: 0;
                        right: 0;
                        color: #C0BDCC;
                        font-size: 15px;
                        bottom: 0;
                        padding: 20px;
                    }
                }
            }

            .cmn-checkbox{
                width: 100%;
                margin-left: 10px;
                a{
                    margin-left: 27px;
                    color: $primary-sky-color;
                }
            }
            .btn{
                margin-top: 40px;
                min-width: 299px;
                margin-left: 10px;
                @media only screen and (max-width:$Small){
                    margin-top: 30px;
                }
            }
        }
      }
  
}