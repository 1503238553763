footer {
    @include flexbox;
    flex-direction: column;
    background-color: $primary-color;
    color: $footer-txt-color;
    width: 100%;
    position: relative;

    h4 {
        color: $white-color;
        @include font-family($oswald-bold, bold);
        margin: 5px 0 23px 0;

        @media only screen and (max-width:$medium) {
            margin: 0px 0 10px 0;
        }
    }

    a {
        color: $footer-txt-color;
    }

    .footer-info {
        width: 100%;
        padding: 60px 0 40px 0;

        @media only screen and (max-width:$large) {
            padding: 40px 0;
        }

        @media only screen and (max-width:$medium) {
            padding: 13px 0 0;
        }

        .row {
            justify-content: space-between;
        }

        .grid-item {
            align-items: flex-start;
            max-width: 310px;

            @media only screen and (max-width:$exMedium) {
                max-width: 25%;
            }

            @media only screen and (max-width:$medium) {
                max-width: 100%;
                width: 100%;
                padding: 15px;
                border-top: 1px solid rgba(255, 255, 255, 0.1);
            }

            ul {
                li {
                    margin-bottom: 10px;

                    a {
                        letter-spacing: 0.45px;
                        font-size: 18px;
                        word-break: break-word;

                        // @media only screen and (max-width:$large) {
                        //     font-size: 16px;
                        // }

                        @media only screen and (max-width:$large) {
                            font-size: 14px;
                            line-height: 22px;
                        }

                        &:hover {
                            color: $white-color;
                        }
                    }

                    &:last-child {
                        @media only screen and (max-width:$medium) {
                            margin-bottom: 0;
                        }
                    }
                }
            }

            &.connect-to {

                @media only screen and (max-width:$medium) {
                    border-top: transparent;
                    padding-bottom: 0;
                }

            }

            &.stay-at {
                p {
                    font-size: 16px;
                    line-height: 25px;
                    max-width: 260px;
                    margin-bottom: 13px;
                    @media only screen and (max-width:$large) {
                        font-size: 14px;
                        line-height: 22px;
                    }
                    @media only screen and (max-width:$medium) {
                        max-width: 100%;
                    }

                    // @media only screen and (max-width:$Small) {
                      
                    // }
                }

                form {
                    width: 100%;

                    .form-group,.form__label-row,.form__entry {
                        margin-bottom: 6px;
                        @include flexbox;
                        width: 100%;
                        flex-direction: column;
                        // &:last-child {
                        //     @media only screen and (max-width:$medium) {
                        //         margin-bottom: 0;
                        //     }
                        // }

                        // .form-control {
                        //     background-color: transparent;
                        //     color: $white-color;
                        //     border-color: $footer-txt-color;

                        //     &:hover {
                        //         border-color: $white-color;
                        //     }

                        //     &.email {
                        //         background: url("../../../images/send.svg") no-repeat center right 20px;
                        //         padding-right: 50px;

                        //         @media only screen and (max-width:$medium) {
                        //             padding-right: 40px;
                        //             background-position: center right 10px;
                        //         }
                        //     }
                        // }

                        // .form-control::-webkit-input-placeholder {
                        //     @include opacity(1);
                        //     color: $primary-sky-color;
                        // }

                        // .form-control::-moz-placeholder {
                        //     @include opacity(1);
                        //     color: $primary-sky-color;
                        // }

                        // .form-control:-ms-input-placeholder {
                        //     @include opacity(1);
                        //     color: $primary-sky-color;
                        // }

                        // .form-control:-moz-placeholder {
                        //     @include opacity(1);
                        //     color: $primary-sky-color;
                        // }

                        // .btn {
                        //     min-width: 180px;
                        //     font-size: 18px;
                        //     @include font-family($font-body, 400);
                        //     text-transform: none;

                        //     @media only screen and (max-width:$exMedium) {
                        //         min-width: inherit;
                        //     }

                        //     @media only screen and (max-width:$Small) {
                        //         font-size: 14px;
                        //         line-height: 22px;
                        //     }
                        // }
                    }
                }
            }

            &.product {
                max-width: 270px;

                @media only screen and (max-width:$exMedium) {
                    max-width: 230px;
                }

                @media only screen and (max-width:$medium) {
                    max-width: 100%;
                    width: 50%;
                }
            }

            &.about-at {
                max-width: 230px;

                @media only screen and (max-width:$exMedium) {
                    max-width: 190px;
                }

                @media only screen and (max-width:$medium) {
                    max-width: 100%;
                    width: 50%;
                }
            }

        }

        .footer-site-logo {
            max-width: 250px;
            margin-bottom: 20px;

            @media only screen and (max-width:$exMedium) {
                max-width: 170px;
            }
        }

        address {
            max-width: 230px;
            font-size: 18px;
            color: $footer-txt-color;
            line-height: 25px;
            margin-bottom: 15px;

            @media only screen and (max-width:$large) {
                font-size: 16px;
                line-height: 22px;
            }

            // @media only screen and (max-width:$Small) {
            //     font-size: 14px;
            // }
        }

        .footer-connect {
            @include flexbox;
            align-items: center;
            justify-content: flex-start;
            padding: 18px 0;
            width: 100%;
            border-top: 1px solid rgba(255, 255, 255, 0.10);

            @media only screen and (max-width:$medium) {
                margin: 0 -15px;
                width: calc(100% + 30px);
                padding: 15px;
            }

            &.socail {
                a {
                    @include flexbox;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid $white-color;
                    width: 38px;
                    height: 38px;
                    @include border-radius(50%);
                    margin-right: 12px;
                    font-size: 14px;

                    &:hover {
                        background-color: $white-color;
                        color: $primary-color;

                        &::before {
                            color: $primary-color;
                        }
                    }

                    &::before {
                        color: $white-color;
                    }

                    &.yt {
                        font-size: 10px;
                    }

                    &.lk {
                        font-size: 14px;
                    }
                }
            }

            &.phone {
                border-bottom: 1px solid rgba(255, 255, 255, 0.10);

                @media only screen and (max-width:$medium) {
                    border-bottom: transparent;
                }

                a {
                    font-size: 18px;
                    line-height: 25px;
                    @include flexbox;

                    @media only screen and (max-width:$large) {
                        font-size: 16px;
                        line-height: 22px;
                    }

                    @media only screen and (max-width:$Small) {
                        font-size: 14px;
                    }

                    &:hover {
                        color: $white-color;

                        em {
                            &::before {
                                color: $white-color;
                            }
                        }
                    }

                    em {
                        &::before {
                            font-size: 12px;
                            margin-right: 15px;
                            color: $footer-txt-color;
                        }
                    }
                }
            }
        }

    }

    .footer-copy {
        width: 100%;
        padding: 36px 0;
        border-top: 1px solid $primary-radio;

        @media only screen and (max-width:$large) {
            padding: 20px 0;
        }

        @media only screen and (max-width:$Small) {
            padding: 15px 0;
        }

        .container {
            justify-content: space-between;
            align-items: center;

            @media only screen and (max-width:$Small) {
                flex-direction: column;
                align-items: flex-start;
            }

            .payment-catrds {
                img {
                    display: inline-block;
                    margin-left: 5px;
                    max-width: 50px;
                }
            }

            .copyright {
                color: $footer-secondry-color;
                font-size: 18px;

                @media only screen and (max-width:$large) {
                    font-size: 14px;

                }

                @media only screen and (max-width:$Small) {
                    
                    margin-bottom: 15px;

                }
            }
        }

    }

    .container {
        @media only screen and (max-width:$large) {
            padding: 0 50px;
        }

        @media only screen and (max-width:$exMedium) {
            padding: 0 15px;
        }
    }

    .scrolltop {
            width: 40px;
            height: 40px;
            position: fixed;
            bottom: 80px;
            right: 15px;
            background: $primary-color;
            @include transform(rotate(270deg));
            @include border-radius(50%);
            text-decoration: none;
            font-size: 10px;
            line-height: 1;
            cursor: pointer;
            color: #222;
            z-index: 100;
            @include transition(0.4s);
            @include transform(scale(0));
            @media only screen and (max-width:$large) {
                height: 30px;
                width: 30px;
                font-size: 8px;
            }
            &.up{
                @include transform(scale(1) rotate(270deg));
            }

            em {
                &::before {
                    color: $white-color;
                }
            }
            
    }

    .sib-form {
        @include space-none;
        width: 100%;
    }
    #sib-container{
        @include space-none;
        border: none !important;
        background-color: transparent !important;
    }
    .sib-form-block{
        padding: 0;
    }
    .form__label-row {
        input.input{
            padding: 18px !important;
            display: inline-block;
            width: 100%;
            min-height: 50px;
            @include body-font();
            @include transition(0.3s);
            outline: none;
            @include box-shadow-none;
            @include border-radius(5px);
            @include app-none;
            border: 1px solid $stone-color;
            text-decoration: none !important;
            position: relative;
            box-sizing: border-box;
            background-color: transparent;
            color: $white-color;
            border-color: $footer-txt-color;
            height: auto;
            @media only screen and (max-width:$large){
                padding: 14px !important;
                min-height: 50px;
                font-size: 16px;
                line-height: 20px;
            }
            @media only screen and (max-width:$Small){
                font-size: 14px ; 
                min-height: 40px;
                padding: 9px 15px !important;
            }

            &:hover {
                border-color: $white-color;
            }

            &:focus {
              @include box-shadow(0, 0, 0, 1px $primary-sky-color);
            }

            &.email {
                background: url("../../../images/send.svg") no-repeat center right 20px;
                padding-right: 50px !important;

                @media only screen and (max-width:$medium) {
                    padding-right: 40px;
                    background-position: center right 10px;
                }
            }
        }
        input.input::-webkit-input-placeholder {
            @include opacity(1);
            color: $primary-sky-color;
        }

        input.input::-moz-placeholder {
            @include opacity(1);
            color: $primary-sky-color;
        }

        input.input:-ms-input-placeholder {
            @include opacity(1);
            color: $primary-sky-color;
        }

        input.input:-moz-placeholder {
            @include opacity(1);
            color: $primary-sky-color;
        }
    }
    .sib-form-block__button {
        padding: 17px 26px;
        @include flexbox;
        align-items: center;
        justify-content: center;
        width: auto;
        min-width: 180px;
        min-height: 60px;
        background: $primary-color;
        color: $white-color;
        font-size: 18px;
        line-height: 24px;
        @include font-family($font-body, 400);
        text-transform: none;
        text-align: center;
        @include transition(0.3s);
        outline: none;
        cursor: pointer;
        @include box-shadow-none;
        @include border-radius(5px);
        @include app-none;
        border: 1px solid $primary-color;
        text-decoration: none !important;
        position: relative;
        // @media only screen and (max-width:$large){
        //     min-width: initial;
        // } 
        @media only screen and (max-width:$large){
            padding: 14px 20px;
            min-height: 50px;
            font-size: 16px;
            line-height: 20px;
            min-width: inherit;
        }
        @media only screen and (max-width:$Small){
            font-size: 14px;
            min-height: 40px;
            padding: 9px 15px;
        }
        &:hover {
            border-color: $primary-color;
            color: $primary-color;
            background-color: transparent;
        }
        &.secondary-btn {
            background-color: $primary-sky-color;
            border-color: $primary-sky-color;
            &:hover {
                color: $primary-sky-color;
                background-color: transparent;
                border-color: $primary-sky-color;
            }
        }
      
    }
    
    .sib-form-block__button[disabled],
    .sib-form-block__button:disabled {
        background-color: $stone-light-color;
        border-color: $stone-light-color;
        cursor: not-allowed;
        pointer-events: none;
        color: $white-color;
        @include opacity(1);
    }
    .sib-form .entry__field{
        border: none;
        outline: none;
        @include space-none;
        background-color: transparent;
        display: block;

    }
    .sib-form .entry__field:focus-within{
        box-shadow: none;
    }
    .sib-form .entry__error{
        @include space-none;
        color: $red-color;
    }
}