/*** Default Style Reset ***/

*,
*::before,
*::after {
    @include box(border-box);
}

body {
    @include space-none;
    @include body-font;
    font-smooth: auto;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
    // padding-top: 100px;

}

article, 
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}

img,
a,
input,
nav,
textarea,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
    padding: 0;
    margin: 0;
}
figure {
    margin: 0;
    display: inherit;
}
button {
    border: none;
    background: none;
    cursor: pointer;
    outline: none !important;
}
input:focus,
select:focus {
    outline: 0;
    @include box-shadow-none;
}
ul,
ol,
li {
    padding: 0;
    margin: 0;
    list-style: none;
}
a {
    text-decoration: none;
    outline: none;
    color: $primary-color;
    @include transition(0.4s);
}
img {
    display: inline-block;
    max-width: 100%;
    width: auto;
    height: auto;
}

blockquote,
figure,
address {
    @include space-none;
    font-style: normal;
}

.templates-wrapper {
    overflow: hidden;
    width: 100%;
}
main {
    float: left;
    padding: 0px;
    width: 100%;
}
.container {
    max-width: 100%;
    width: 1396px;
    padding: 0 15px;
    margin: 0 auto;
    @media only screen and (max-width: $large) {
        width: 1200px;
    }
    @media only screen and (max-width: $exMedium) {
        width: 1100px;
    }
   
}
.container-small{
    max-width: 100%;
    width: 1200px;
    padding: 0 15px;
    margin: 0 auto;
    @media only screen and (max-width: $large) {
        width: 1100px;
    }
    @media only screen and (max-width: $exMedium) {
        width: 1000px;
    }
}

.container-fluid {
    width: 100%;
    padding: 0;
    margin: 0 auto;
}
.page-wrapper{
    overflow: hidden;
}

section,
.section {
    float: left;
    width: 100%;
    padding: 0;
}

.divImg {
    @include bg-cmn;
}

p {
    margin-bottom: 30px;
    @media only screen and (max-width:$large){
        margin-bottom: 15px;
    }
}

/*** Heading ***/
h1,
.h1 {
    @include font-xl;
    margin-bottom: 30px;
    @media only screen and (max-width:$large){
        margin-bottom: 15px;
    }
}
h2,
.h2 {
    @include font-lg;
    margin-bottom: 30px;
    @media only screen and (max-width:$large){
        margin-bottom: 15px;
    }
}
h3,
.h3 {
    @include font-md;
    margin-bottom: 30px;
    @media only screen and (max-width:$large){
        margin-bottom: 15px;
    }
}
h4,
.h4 {
    @include font-sm;
    margin-bottom: 30px;
    @media only screen and (max-width:$large){
        margin-bottom: 15px;
    }
}
h5,
.h5,
.subTitle1 {
    @include font-xs;
}
h6,
.h6,
.subTitle2 {
    @include font-xxs;
}



/*** Listing ***/
ul {
    margin: 0px 0px 30px;
    padding: 0;
    @media only screen and (max-width:$exSmall){
        margin-bottom: 15px;
    }
    li {
        list-style: none;
        @include body-font;
        margin: 0px 0px 15px;
        padding: 0px 0px 0px 15px;
        position: relative;

        &:before {
            content: "";
            width: 5px;
            height: 5px;
            position: absolute;
            left: 0px;
            top: 9px;
            background: $light-stone;
            @include border-radius(100%);
        }
    }
}
ul.list-unstyled {
    @include space-none;
    li {
        @include space-none;    
        &:before {
            content: normal;
        }
    }
}
ol {
    margin: 0px 0px 30px;
    padding: 0px;
    list-style: none;
    @media only screen and (max-width:$exSmall){
        margin-bottom: 15px;
    }
    li {
        counter-increment: av-counter;
        @include body-font;
        margin: 0px 0px 20px;
        padding: 0px 0px 0px 20px;
        position: relative;

        &:before {
            content: counter(av-counter) ".";
            counter-increment: none;
            color: $light-stone;
            position: absolute;
            left: 0px;
            top: 0px;
        }
    }
}

.txt-block {
    float: left;
    width: 100%;
    .cmn-border {
        border-bottom: transparent;
        margin-top: -1px;
        padding: 20px;
        @media only screen and (max-width: $Small) {
            padding: 15px;
        }
        h3 {
            margin: 0 0 10px 0;
        }
        p {
            margin: 0 auto;
            padding: 0;
            max-width: 900px;
        }
    }
}
    section{
        .my{
            margin-top: 60px;
            margin-bottom: 60px;
        }
    }
.sky-blue-subtitle{
    position: relative;
    color: $primary-sky-color;
    &::before{
        content: '';
        position: relative;
       display: block;
        width: 50px;
        height: 2px;
        background-color: $primary-sky-color;
        z-index: 1;
        margin-right: 10px;
    }
    
  }
  h2{
      &.sub-title{
          margin-top: 15px;
          @media only screen and (max-width: $large) {
            margin-top: 10px;
        }
      }
  }
strong,.strong,bold {
    @include font-family($sans-bold, bold);
}