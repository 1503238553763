.row {
    // border-left: 1px solid $black-color;
    // float: left;
    @include flexbox;
    flex-direction: row;
    flex-wrap: wrap;
    // overflow: hidden;
    margin: 0 -15px;
    // max-width: 1760px;

    .grid-item {
        @include flexbox;
         width: 25%;
        // overflow: hidden;
        padding: 0 15px;
        flex-direction: column;
        @media only screen and (max-width: $exMedium) {
            width: 33.33%;
        }
        @media only screen and (max-width: $Small) {
            width: 50%;
        }
        &.grid-item-width2 {
            width: 50%;
            // border-left: 1px solid transparent;
            @media only screen and (max-width: $exMedium) {
                width: 66.66%;
            }
            @media only screen and (max-width: $smMedium) {
                width: 100%;
            }
        }
        &.grid-item-width3 {
            width: 33.33%;
            @media only screen and (max-width: $Small) {
                width: 50%;
            }
            @media only screen and (max-width: $exSmall) {
                width: 100%;
            }
        }
        &.grid-item-width6 {
            width: 16.66%;
            @media only screen and (max-width: $exMedium) {
                width: 20%;
            }
            @media only screen and (max-width: $medium) {
                width: 25%;
            }
            @media only screen and (max-width: $smMedium) {
                width: 33%;
            }
            // @media only screen and (max-width: $Small) {
            //     width: 50%;
            // }
            @media only screen and (max-width: $exSmall) {
                width: 50%;
            }
        }
        &.grid-item-width4 {
            width: 100%;
        }
        &.grid-item-width3x {
            width: 75%;
            @media only screen and (max-width: $smMedium) {
                width: 100%;
            }
        }
        &.half-width {
            @media only screen and (max-width: $exMedium) {
                width: 33.33%;
            }
            @media only screen and (max-width: $smMedium) {
                width:50%;
            }
            
            @media only screen and (max-width: $exSmall) {
                width: 100%;
            }
        }
     
    }

  
}


