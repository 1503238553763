@mixin linear-gradient($direction, $color-stops...) {
  // Direction has been omitted and happens to be a color-stop
  @if is-direction($direction) == false {
    $color-stops: $direction, $color-stops;
    $direction: 180deg;
  }
  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}

/*** Font Family  ***/

$font-body: "OpenSans-Regular";
$sans-300:"OpenSans-Light";
$sans-500:"OpenSans-Medium";
$sans-600:"OpenSans-SemiBold";
$sans-bold:'OpenSans-Bold';
$sans-900:"OpenSans-ExtraBold";

$oswald-600:"Oswald-SemiBold";
$oswald-400:'Oswald-Regular';
$oswald-500:'Oswald-Medium';
$oswald-200:'Oswald-ExtraLight';
$oswald-300:'Oswald-Light';
$oswald-bold:'Oswald-Bold';




$interLight: "Inter Light";
$interMedium: "Inter Medium";
$interNormal: "Inter Normal";



@mixin font-family($face, $weight) {
  font-family: $face;
  font-weight: $weight;
}

@mixin body-font {
  @include font-family($font-body, 400);
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0px;
  color: $stone-color;
  @media only screen and (max-width:$large){
    font-size: 14px;
    line-height: 20px;
  }

}

/*** Font-size as h1-h6 headings ***/

@mixin font-xl {
  font-size: 82px;
  line-height: 96px;
  letter-spacing: 0px;
  text-transform: capitalize;
  @include font-family($oswald-bold, 700);
  color: $title-color;
  word-break: break-word;
  @media only screen and (max-width:$large){
    font-size: 58px;
    line-height: 66px;
  }
  @media only screen and (max-width:$Small){
    font-size: 38px;
    line-height: 50px;
  }

}

@mixin font-lg {
  font-size: 48px;
  line-height: 60px;
  letter-spacing: 0px;
  text-transform: capitalize;
  @include font-family($oswald-bold, 700);
  color: $title-color;
  word-break: break-word;
  @media only screen and (max-width:$large){
    font-size: 30px;
    line-height: 34px;
  }

  @media only screen and (max-width:$Small){
    font-size: 26px;
    line-height: 30px;
  }
}

@mixin font-md {
  font-size: 32px;
  line-height: 42px;
  text-transform: capitalize;
  @include font-family($oswald-bold, 700);
  color: $title-color;
  word-break: break-word;
  @media only screen and (max-width:$large){
    font-size: 26px;
    line-height: 28px;
  }

  @media only screen and (max-width:$Small){
    font-size: 22px;
    line-height: 30px;
  }
  
}

@mixin font-sm {
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0px;
  @include font-family($font-body,400);
  color: $title-color;
  word-break: break-word;
  @media only screen and (max-width:$large){
      font-size: 22px;
      line-height: 28px;
  }
  @media only screen and (max-width:$Small){
    font-size: 20px;
    line-height: 28px;
  }
}

@mixin font-xs {
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0px;
  @include font-family($font-body, 400);
  color: $stone-color;
  word-break: break-word;
  @media only screen and (max-width:$large){
      font-size: 18px;
      line-height: 26px;
  }
  @media only screen and (max-width:$Small){
    font-size: 16px;
    line-height: 24px;
  }

}

@mixin font-xxs {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0px;
  @include font-family($font-body, 400);
  color: $stone-color;
  word-break: break-word;
  @media only screen and (max-width:$large){
    font-size: 14px;
    line-height: 20px;
  }
  @media only screen and (max-width:$Small){
    font-size: 12px;
    line-height: 18px;
  }

}
@mixin  body-small {
    font-size: 11px;
    line-height: 17px;
}
@mixin font-bold {
    @include font-family($sans-bold,700);
}


@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -o-border-radius: $radius;
  border-radius: $radius;
}

@mixin opacity($opacity) {
  -webkit-opacity: $opacity;
  -moz-opacity: $opacity;
  -o-opacity: $opacity;
  -ms-opacity: $opacity;
  opacity: $opacity;
}

@mixin transition($seconds, $timing: ease) {
  -webkit-transition: all $seconds $timing;
  -moz-transition: all $seconds $timing;
  -ms-transition: all $seconds $timing;
  -o-transition: all $seconds $timing;
  transition: all $seconds $timing;
}

@mixin transform($property) {
  -o-transform: $property;
  -webkit-transform: $property;
  -ms-transform: $property;
  -moz-transform: $property;
  transform: $property;
}

@mixin flexbox() {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flexboxImp() {
  display: -ms-flexbox !important;
  display: -webkit-flex !important;
  display: flex !important;
}

@mixin text-shadow($x-axis, $y-axis, $blur, $color) {
  -ms-text-shadow: $x-axis $y-axis $blur $color;
  -o-text-shadow: $x-axis $y-axis $blur $color;
  -webkit-text-shadow: $x-axis $y-axis $blur $color;
  -moz-text-shadow: $x-axis $y-axis $blur $color;
  text-shadow: $x-axis $y-axis $blur $color;
}

@mixin box-shadow($x-axis, $y-axis, $blur, $color) {
  -ms-box-shadow: $x-axis $y-axis $blur $color;
  -o-box-shadow: $x-axis $y-axis $blur $color;
  -webkit-box-shadow: $x-axis $y-axis $blur $color;
  -moz-box-shadow: $x-axis $y-axis $blur $color;
  box-shadow: $x-axis $y-axis $blur $color;
}

@mixin box-shadow-none {
  -ms-box-shadow: none;
  -o-box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

@mixin text-shadow-none {
  -ms-text-shadow: none;
  -o-text-shadow: none;
  -webkit-text-shadow: none;
  -moz-text-shadow: none;
  text-shadow: none;
}

@mixin box($property) {
  box-sizing: $property;
  -moz-box-sizing: $property;
  -webkit-box-sizing: $property;
  -o-box-sizing: $property;
}

@mixin trans-anim-none {
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
}

@mixin space-none {
  margin: 0;
  padding: 0;
}

@mixin app-none {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
  appearance: none;
}

@mixin point-none {
  -webkit-pointer-events: none;
  -moz-pointer-events: none;
  -o-pointer-events: none;
  -ms-pointer-events: none;
  pointer-events: none;
}

/*** Background properties  ***/

@mixin bg-cmn {
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
@mixin bg-parallax {
  background-attachment: fixed;
  @include bg-cmn;
}

@mixin divider {
  width: 1px;
  position: absolute;
  bottom: 0;
  top: 0; 
  height: 60px;
  margin: auto;
  background-color: $white-color;
  content: "";  
  @media only screen and (max-width:$large){
    height: 50px;
  }
  @media only screen and (max-width:$exSmall){
    height: 30px;
  }
}

@mixin top-box($max-witdh){
  max-width: $max-witdh;
  margin: 0 auto;
}

@mixin primaryHeader {
    body{
      padding-top: 100px;
  }
  header{
      &.site-header{
          background: $primary-color;
      }
      
  } 
}