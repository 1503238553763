/* Alert */
.alert {
    padding: 15px 25px;
    border: 1px solid $primary-color;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 15px;
    color: $primary-color;
    &.alert-danger {
        border-color: $red-color;
        * {
            color: $red-color;
        }
        ul {
            &:last-child {
                margin-bottom: 0;
            }
            li {
                &:before {
                    background: $red-color;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    &.alert-success {
        border-color: $lighter-green;
        * {
            color: $lighter-green;
        }
    }
}
