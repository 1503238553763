/*** Responsive ios input ***/

@media only screen and (max-width: 991px) {
    select,
    textarea,
    input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    input[type="tel"],
    input[type="url"] {
        // font-size: 16px !important;
        font-size: 16px;
    }
}

/*** Common Forms Elements ***/

label {
    color: $stone-color;
    @include font-family($sans-500, 500);
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0px;
    display: inline-block;
    text-transform: capitalize;
    @media only screen and (max-width: $large) {
        font-size: 12px;
        line-height: 18px;
    }
}

.form-group {
    position: relative;
    margin-bottom: 20px;
    @media only screen and (max-width: $large) {
        margin-bottom: 15px;
    }
    label {
        margin-bottom: 6px;
        text-transform: capitalize;
        & > em {
            color: $red-color;
            font-style: normal;
        }
    }
    &.invalid {
        label {
            color: $red-color;
            & > em {
                color: $red-color;
            }
        }
        .form-control {
            border-color: $red-color;
        }
        .validate-message, .error {
            color: $red-color;
        }
    }
    &.valid {
        label {
            color: $lighter-green;
            & > em {
                color: $lighter-green;
            }
        }
        .form-control {
            border-color: $lighter-green;
        }
        .validate-message, .error {
            color: $lighter-green;
        }
    }
}

.validate-message, .error {
    margin-top: 6px;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0px;
    @media only screen and (max-width: $large) {
        font-size: 12px;
        line-height: 22px;
    }
}

.form-control {
    padding: 18px;
    display: inline-block;
    width: 100%;
    min-height: 50px;
    background: $white-color;
    @include body-font();
    @include transition(0.3s);
    outline: none;
    @include box-shadow-none;
    @include border-radius(5px);
    @include app-none;
    border: 1px solid $stone-light-color;
    text-decoration: none !important;
    position: relative;
    @media only screen and (max-width: $large) {
        padding: 14px;
        min-height: 50px;
        font-size: 16px;
        line-height: 20px;
    }
    @media only screen and (max-width: $Small) {
        font-size: 14px;
        min-height: 40px;
        padding: 9px 15px;
    }

    &:hover {
        border-color: $primary-sky-color;
    }

    &:focus {
        @include box-shadow(0, 0, 0, 1px $primary-sky-color);
    }
}

.form-control::-webkit-input-placeholder {
    @include opacity(0.3);
    color: $black-color;
}
.form-control::-moz-placeholder {
    @include opacity(0.3);
    color: $black-color;
}
.form-control:-ms-input-placeholder {
    @include opacity(0.3);
    color: $black-color;
}
.form-control:-moz-placeholder {
    @include opacity(0.3);
    color: $black-color;
}

.form-control[disabled],
.form-control:disabled {
    background-color: $gray-e5;
    border-color: $gray-e5;
    color: $stone-color;
    // @include opacity(0.6);
    cursor: not-allowed;
    pointer-events: none;
    em {
        right: 16px;
    }
}

.cmn-select {
    @include app-none;
    background: url("../../../images/drop-down-arrow.svg") no-repeat right 20px
        center;
    padding-right: 55px;
    background-size: 20px auto;
    @media only screen and (max-width: $exMedium) {
        padding-right: 45px;
        background-size: 16px auto;
        background-position: right 15px center;
    }
}

/*** Select 2  ***/
.select2-container--default {
    .select2-selection--single {
        &.select2-selection {
            height: 100%;
            border: none;
            display: flex;
            align-items: center;
            .select2-selection__rendered {
                @extend .form-control;
            }
            .select2-selection__arrow {
                height: 100%;
                width: 40px;
                background: url("../../../images/drop-down-arrow.svg") no-repeat
                    center;
                right: 10px;
                b {
                    display: none;
                }
                @media only screen and (max-width: $smMedium) {
                    width: 15px;
                }
            }
        }
    }
}
.select2-container {
    .select2-search {
        padding: 1px;

        .select2-search__field {
            @extend .form-control;
        }
    }

    .select2-results {
        ul {
            @extend .list-unstyled;
            padding: 5px;
            li {
                padding: 10px 20px;
                @include border-radius(5px);
                &.select2-results__option--highlighted.select2-results__option--selectable {
                    background-color: $primary-color;
                }
                &.select2-results__option--selected {
                    background-color: $primary-light-color;
                }
            }
        }
    }
}
/*** Check Box  ***/

.cmn-checkbox {
    display: inline-block;
    position: relative;
    margin-right: 15px;
    input {
        width: 0;
        height: 0;
        position: absolute;
        font-size: 0;
        @include opacity(0);
        &:checked + label {
            // @include font-family($sans-bold,bold);
            &::before {
                border-color: $primary-radio;
                background-color: $primary-radio;
                content: "\e901";
            }
        }
    }
    label {
        display: inline-block;
        padding-left: 32px;
        position: relative;
        cursor: pointer;
        word-break: break-word;
        color: $title-color;
        @media only screen and (max-width: $large) {
            padding-left: 25px;
        }
        &::before {
            content: "";
            @include border-radius(3px);
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto 0;
            width: 20px;
            height: 20px;
            border: 1px solid $stone-color;
            background-color: $white-color;
            font-family: "icomoon";
            @include transition(0.4s);
            /* Better Font Rendering =========== */
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            color: $white-color;
            @include flexbox;
            align-items: center;
            justify-content: center;
            font-size: 10px;
            @media only screen and (max-width: $large) {
                width: 16px;
                height: 16px;
                font-size: 6px;
            }
        }
    }
}

.cmn-checkbox {
    input[disabled],
    input:disabled {
        & + label {
            cursor: not-allowed;
            pointer-events: none;
            @include opacity(0.5);
        }
    }
}

/*** Radio Button  ***/

.cmn-radio {
    display: inline-block;
    position: relative;
    margin-right: 15px;
    input {
        width: 0;
        height: 0;
        position: absolute;
        font-size: 0;
        @include opacity(0);
        &:checked + label {
            // @include font-family($sans-bold,bold);
            &::before {
                background-color: $primary-radio;
                border-color: $primary-radio;
                @include box-shadow(inset 0px, 0px, 0px, 5px $white-color);
            }
        }
    }
    label {
        display: inline-block;
        padding-left: 32px;
        position: relative;
        cursor: pointer;
        word-break: break-word;
        color: $title-color;
        @media only screen and (max-width: $large) {
            padding-left: 25px;
        }
        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto 0;
            width: 20px;
            height: 20px;
            border: 1px solid $stone-color;
            background-color: $white-color;
            @include border-radius(50%);
            @include transition(0.4s);
            word-break: break-word;
            @media only screen and (max-width: $large) {
                width: 16px;
                height: 16px;
            }
        }
    }
}

.cmn-radio {
    input[disabled],
    input:disabled {
        & + label {
            cursor: not-allowed;
            pointer-events: none;
            @include opacity(0.5);
        }
    }
}
#isCheckedDiv {
    label {
        text-transform: none;
    }
}
.decline_reason {
    label {
        text-transform: none;
    }
}