.cmn-banner{
    position: relative;
    min-height: 320px;
    padding: 149px 0 49px 0;
    @media only screen and (max-width:$large){
        min-height: 200px;
        padding: 100px 0 20px 0;
    }
    &::before{
        content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color:$primary-color;
            // mix-blend-mode: multiply;
            opacity: 0.85;
            z-index: 1;
    }
    img{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        height: 100%;
        object-fit: cover;
        width: 100%;
    }
    .container{
        position: relative;
        z-index: 2;
        h1{
            color: $white-color;
        }
    }
   
}