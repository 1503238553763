.static-link {
    display: inline-block;
    color: $primary-color;

    @include transition(0.4s);
    &:hover {
        text-decoration: underline;
    }
}

.d-flex {
    @include flexbox;
}

.hide {
    display: none;
}

.d-align-center {
    align-items: center;
}
.d-align-end {
    align-items: flex-end;
}
.d-align-start {
    align-items: flex-start;
}
.d-just-center {
    justify-content: center;
}
.d-just-end {
    justify-content: flex-end;
}
.d-space-between {
    justify-content: space-between;
}
.d-flex-row {
    flex-direction: row;
}
.d-flex-row-reverse {
    flex-direction: row-reverse;
}
.d-flex-column {
    flex-direction: column;
}

.d-just-start {
    justify-content: flex-start;
}
.d-flex-wrap {
    flex-wrap: wrap;
}

.t-flex-column {
    @media only screen and (max-width: $medium) {
        flex-direction: column;
    }
}

.t-txt-center {
    @media only screen and (max-width: $medium) {
        text-align: center;
    }
}

.text-right {
    text-align: right;
}

.t-align-normal {
    @media only screen and (max-width: $medium) {
        align-items: normal;
    }
}

.m-flex-column {
    @media only screen and (max-width: $Small) {
        flex-direction: column;
    }
}
.sm-flex-column {
    @media only screen and (max-width: $smMedium) {
        flex-direction: column;
    }
}

.m-flex-row {
    @media only screen and (max-width: $Small) {
        flex-direction: row;
    }
}

.m-txt-left {
    @media only screen and (max-width: $Small) {
        text-align: left;
    }
}

.m-flex-wrap {
    @media only screen and (max-width: $Small) {
        flex-wrap: wrap;
    }
}

.txt-center {
    text-align: center;
}
.just-left {
    justify-content: left;
}

.imgElm {
    @include bg-cmn;
}

.desktop {
    display: none;
    @media only screen and (min-width: ($medium +1)) {
        display: block;
    }
}
.mobile,
.mobile-menu-bar {
    display: none;
    @media only screen and (max-width: $medium) {
        display: block;
    }
}

.t-align-center {
    @media only screen and (max-width: $medium) {
        align-items: center;
    }
}

.m-txt-center {
    h2 {
        @media only screen and (max-width: $Small) {
            margin: 0 auto;
        }
    }
}

.author-name {
    color: $light-stone !important;
}

.m-b-0 {
    @media only screen and (max-width: $Small) {
        margin-bottom: 0 !important;
    }
}

.m-relative {
    @media only screen and (max-width: $Small) {
        position: relative;
    }
}
.body_fixed {
    overflow: hidden;
}

.m-block {
    @media only screen and (max-width: $Small) {
        display: block !important;
    }
}

.disablelink {
    opacity: 0.4;
    cursor: not-allowed;
    pointer-events: none;
}

.d-align-start {
    align-items: flex-start;
}

.m-align-start {
    @media only screen and (max-width: $Small) {
        align-items: flex-start !important;
    }
}

.m-align-center {
    @media only screen and (max-width: $Small) {
        align-items: center;
    }
}

.m-align-inherit {
    @media only screen and (max-width: $Small) {
        align-items: inherit;
    }
}

caption,
.caption {
    font-size: 13px;
    line-height: 16.86px;
    letter-spacing: 0.4px;
}
.overline {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 1.5px;
}

.cmn-border {
    border: 1px solid $black-color;
}

.border {
    &-left-0 {
        border-left: 0;
    }
    &-right-0 {
        border-right: 0;
    }
    &-top-0 {
        border-top: 0;
    }
    &-bottom-0 {
        border-bottom: 0;
    }

    &-y-0 {
        border-left: 0;
        border-right: 0;
    }

    &-x-0 {
        border-top: 0;
        border-bottom: 0;
    }
}

.borderOveride {
    margin-bottom: -1px;
}

.ml-0 {
    margin-left: 0 !important;
}

.mb-10px {
    margin-bottom: 10px;
}

.p-0 {
    padding: 0 !important;
}

.m-0 {
    margin: 0 !important;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-capitalize {
    text-transform: capitalize;
}

// Colors
.txt-primary {
    color: $primary-color;
}
.txt-white {
    color: $white-color;
}
.overlay-bg {
    position: relative;
    z-index: 1;
    &:before {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 0.5;
        background-color: $primary-color;
        content: "";
        z-index: -1;
    }
}
