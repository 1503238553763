/*** App color variable ***/
$primary-color:#151F66;
$primary-sky-color:#8FAEE6;
$primary-light-color:#D0D2E0;
$white-color: #ffffff;
$black-color: #000000;
$stone-color:#707070;
$stone-light-color:#D2D2D2;
$gray-12:#A2A2A2;
$gray-e5:#E5E5E5;
$gray-f3:#F3F3F3;
$gray-da:#DADADA;
$gray-ef:#efefef;
$title-color:#303030;
$sub-title:#021F36;
$primary-radio:#274CB2;
$footer-txt-color:#A3AFD4;
$footer-secondry-color:#D0D2E0;
$gray-d:#373C48;
$shadow-color:#0000001C;
$pass-eye-color:#C0BDCC;

$red-color:#FF0000;
$lighter-green:#00a653;
$green-color:#023B20;
// $stone-color:#0D0D0D;
$light-stone:#404040;

$discount-red:#EF2D56;

// Tracker colors
$tracker-color-1 : #152363;
$tracker-color-2 : #354B84;
$tracker-color-3 : #5C7CAD;
$tracker-color-4 : #7CA4CD;
$tracker-color-5 : #9ECFF0;
$tracker-color-6 : #93C6CC;
$tracker-color-7 : #89BDAA;
$tracker-color-8 : #7EB486;
$tracker-color-9 : #74AB63;

// $mobile: 539px;
$exSmall: 576px;
$Small: 767px;
$smMedium: 991px;
$medium: 1024px;
$medium-1170: 1170px;
$exMedium:1280px;
$large:1440px;
$exLarge:1680px;
$container:1840px;