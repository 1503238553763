/*** Common All Buttons ****/

.btn {
    padding: 17px 26px;
    @include flexbox;
    align-items: center;
    justify-content: center;
    width: auto;
    min-width: 180px;
    min-height: 60px;
    background: $primary-color;
    color: $white-color;
    font-size: 20px;
    line-height: 24px;
    @include font-family($sans-500, 500);
    text-align: center;
    text-transform: capitalize;
    @include transition(0.3s);
    outline: none;
    cursor: pointer;
    @include box-shadow-none;
    @include border-radius(5px);
    @include app-none;
    border: 1px solid $primary-color;
    text-decoration: none !important;
    position: relative;
    @media only screen and (max-width:$large){
        min-width: initial;
        padding: 14px 20px;
        min-height: 50px;
        font-size: 16px;
        line-height: 20px;
    } 
    // @media only screen and (max-width:$exMedium){
      
    // }
    @media only screen and (max-width:$Small){
        font-size: 14px;
        min-height: 40px;
        padding: 9px 15px;
    }
    &:hover {
        border-color: $primary-color;
        color: $primary-color;
        background-color: transparent;
    }
    &.secondary-btn {
        background-color: $primary-sky-color;
        border-color: $primary-sky-color;
        &:hover {
            color: $primary-sky-color;
            background-color: transparent;
            border-color: $primary-sky-color;
        }
    }
    &.btn-small {
        padding: 7px 16px;
        min-height: 40px;
        font-size: 18px;
        @media only screen and (max-width:$large){
            font-size: 14px;
        }
        @media only screen and (max-width:$medium){
            font-size: 12px;
            padding: 4px 10px;
            min-height: 35px;
        }
        &:hover {
            background-color: transparent;
            color: $primary-color;
            border-color: $primary-color;
        }
    }
}

.btn[disabled],
.btn:disabled {
    background-color: $stone-light-color;
    border-color: $stone-light-color;
    cursor: not-allowed;
    pointer-events: none;
    color: $white-color;
}

.hyper-link {
    color: $primary-color;
    @include transition(0.3s);
    outline: none;
    cursor: pointer;
    position: relative;
    @include flexbox;
    align-items: center;
    &::after {
        font-family: "icomoon" !important;
        speak: never;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\e900";
        display: inline-block;
        @include opacity(0);
        margin-left: -15px;
        @include transition(0.4s);
        position: relative;
        top: 2px;
    }
    &:hover {
        color: $primary-color;
        &::after {
            margin-left: 15px;
            @include opacity(1);
        }
    }

    &.abs {
        display: flex;
        padding-right: 45px;
        word-break: break-word;
        &::after {
            position: absolute;
            right: 25px;
            top: 4px;
        }
        &:hover {
            span {
                color: $primary-sky-color;
            }
            &::after {
                right: 0;
            }
        }
    }
}

.btn-discount{
    background-color: $discount-red;
    color:$white-color;
    @include border-radius(5px);
    font-size: 14px;
    padding: 5px 15px;
    display: inline-block;
    @include transition(0.4s);
    @include font-family($font-body,400);
    text-align: center;
    &:hover{
        @include opacity(0.8);
    }
}